/* eslint-disable no-warning-comments */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable require-jsdoc */
import React from 'react';
import MartyConnect from '../components/marty-connect/marty-connect.jsx';

const ConnectTab = props => <MartyConnect {...props} />;

export default ConnectTab;
